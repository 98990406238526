<template>
  <div class="cssloader">
    <svg class="pl" viewBox="0 0 64 64" width="64px" height="64px" xmlns="http://www.w3.org/2000/svg">
	<defs>
		<linearGradient id="grad" x1="0" y1="0" x2="0" y2="1">
			<stop offset="0%" stop-color="#000" />
			<stop offset="100%" stop-color="#fff" />
		</linearGradient>
		<mask id="grad-mask">
			<rect x="0" y="0" width="64" height="64" fill="url(#grad)" />
		</mask>
	</defs>
	<circle class="pl__ring" cx="32" cy="32" r="26" fill="none" stroke="#979899" stroke-width="12" stroke-dasharray="169.65 169.65" stroke-dashoffset="-127.24" stroke-linecap="round" transform="rotate(135)" />
	<g fill="#181B1C">
		<circle class="pl__ball1" cx="32" cy="45" r="6" transform="rotate(14)" />
		<circle class="pl__ball2" cx="32" cy="48" r="3" transform="rotate(-21)" />
	</g>
	<g mask="url(#grad-mask)">
		<circle class="pl__ring" cx="32" cy="32" r="26" fill="none" stroke="#979899" stroke-width="12" stroke-dasharray="169.65 169.65" stroke-dashoffset="-127.24" stroke-linecap="round" transform="rotate(135)" />
		<g fill="#181B1C">
			<circle class="pl__ball1" cx="32" cy="45" r="6" transform="rotate(14)" />
			<circle class="pl__ball2" cx="32" cy="48" r="3" transform="rotate(-21)" />
		</g>
	</g>
</svg>
</div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style>
/** page **/
.cssloader {
  padding-top: calc(45vh - 100px);
  padding-bottom: calc(45vh - 22%);
}
@media (min-width:768px){
.cssloader{
  padding-bottom: calc(45vh - 28%);
}
}
/** loader **/
.sh1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: #979899  transparent transparent transparent;
  margin: 0 auto;
  animation: shk1 1s ease-in-out infinite normal;
}

.sh2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent  transparent #979899  transparent ;
  margin: -50px auto 0;
  animation: shk2 1s ease-in-out infinite alternate;
}

/** animation starts here **/
@keyframes shk1 {
  0% {
    transform: rotate(-360deg);
  }  
  
  100% {
  }
}

@keyframes shk2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
  }
}

.lt {
  color: #1e6667;
  font-family: 'Cairo', sans-serif;
  margin: 30px auto;
  text-align: center;
  font-weight: 100;
  
}




.pl {
	display: block;
	width: 8em;
	height: 8em;
  margin-right: 48vw;
/* margin-bottom: 2vh; */

}
.pl__ring,
.pl__ball1,
.pl__ball2 {
	animation-duration: 2s;
	animation-timing-function: ease-in-out;
	animation-iteration-count: infinite;
	transform-origin: 32px 32px;
}
.pl__ring {
	animation-name: ring;
}
.pl__ball1 {
	animation-name: ball1;
}
.pl__ball2 {
	animation-name: ball2;
}

/* Dark theme  */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue),10%,10%);
		--fg: hsl(var(--hue),10%,90%);
	}
}

/* Animation */
@keyframes ring {
	from {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: -122.52;
		transform: rotate(135deg);
	}
	15% {
		animation-timing-function: ease-in;
		stroke-dashoffset: -122.52;
		transform: rotate(90deg);
	}
	35% {
		animation-timing-function: ease-out;
		stroke-dashoffset: -65.34;
		transform: rotate(297.5deg);
	}
	55% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: -122.52;
		transform: rotate(505deg);
	}
	70% {
		animation-timing-function: ease-in-out;
		stroke-dashoffset: -122.52;
		transform: rotate(490deg);
	}
	85%,
	to {
		stroke-dashoffset: -122.52;
		transform: rotate(497.5deg);
	}
}
@keyframes ball1 {
	from {
		transform: rotate(14deg);
	}
	20% {
		transform: rotate(-7deg);
	}
	60% {
		transform: rotate(399deg);
	}
	75% {
		transform: rotate(361deg);
	}
	90%,
	to {
		transform: rotate(374deg);
	}
}
@keyframes ball2 {
	from {
		transform: rotate(-21deg);
	}
	25% {
		transform: rotate(-47deg);
	}
	60% {
		transform: rotate(364deg);
	}
	75% {
		transform: rotate(326deg);
	}
	90%,
	to {
		transform: rotate(339deg);
	}
}
@media(max-width:786px){
  .pl{
    margin-right: 32vw;
margin-bottom: 2vh;
  }
}
</style>
<template>
   <nav class="navbar navbar-expand navbar-light bg-white static-top osahan-nav sticky-top">
         &nbsp;&nbsp; 
         <button class="btn btn-link btn-sm text-secondary order-1 order-sm-0" id="sidebarToggle">
            <i class="fas fa-bars"></i>
         </button> &nbsp;&nbsp;
         <router-link to="/" class="navbar-brand mr-1 logo"><img class="img-fluid" alt src="../../assets/Da7ktna_logo.png"></router-link>
         <form class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-5 my-2 my-md-0 osahan-navbar-search" @submit.prevent="SearchData()">
            <div class="input-group">
               <input type="text" class="form-control" placeholder="بحث ..." v-model="search">
               <div class="input-group-append">
                  <button class="btn btn-light" type="button">
                     <i class="fas fa-search"></i> 
                  </button>
               </div>
            </div>
         </form>
         <ul class="navbar-nav ml-auto osahan-right-navbar">
            <li class="nav-item mx-1">
               <router-link to="/" class="nav-link1" style="color: #fff;">
               <i class="fas fa-plus-circle fa-fw"></i>
               اشتراك
               </router-link>
            </li>         
            <li class="nav-item mx-1">
               <router-link to="/Logins" class="nav-link1" style="color: #fff;">
               <i class="fas fa-plus-circle fa-fw"></i>
               تسجيل الدخول
               </router-link>
            </li> 
         </ul>
   </nav>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
export default {
    name: 'AppHeader',
    data() {
    return {
        search: ""
      };
    },
       setup() {
        // const router = useRouter();
        const showHeader = ref(true);
        const categories = ref([]);

        try {
         HTTP.get(`GetAllCategories.php`).then((res) => {
          categories.value = res.data.Categories; 
        });
      } catch (err) {
        console.log(err);
      }
        
        return { categories,showHeader };
    },
   //  mounted() {
   //      const s = document.createElement('script');
   //      s.type = 'text/javascript';
   //      s. src = '/static/js/custom.js';
   //      document.body.appendChild(s);
   //  },
    methods: {
        SearchData() {
           this.$router.push({ name: "Searchs", params: { search_id: this.search } });
        }
    },
}
</script>

<style >

.logo {
    width: 8%;
}

@media (max-width:428px){
    .logo {
       width: 22%;
    }

}
@media (max-width:360px){
    .logo {
       width: 22%;
    }
 
}
</style>
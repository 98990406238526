<template>
   <footer class="sticky-footer">
      <div class="container">
         <div class="row no-gutters">
            <div class="col-lg-6 col-sm-6">
               <p class="mt-1 mb-0">&copy; Copyright 2023 <strong class="text-dark">da7ktna</strong>. All Rights Reserved<br>
                  <small class="mt-0 mb-0">Made with <i class="fas fa-heart text-danger"></i> by <a class="text-primary" target="_blank" href="https://sudani.sd/">Sudani</a>
                  </small>
               </p>
            </div>
            <div class="col-lg-6 col-sm-6 text-right">
               <div class="app">
                  <router-link to="/"><img alt src="../../assets/Sudani-Logo.png"></router-link>
               </div>
            </div>
         </div>
      </div>
   </footer>
</template>

<script>
export default {
   name: 'AppFooter',
    
}
</script>

<style>

</style>
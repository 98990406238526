<template>
    <ul class="sidebar navbar-nav">
        <li class="nav-item active">
            <router-link to="/" class="nav-link">
               <i class="fas fa-fw fa-home"></i>
               <span>الصفحة الرئيسية</span>
            </router-link>
        </li>
        <li class="nav-item" v-for="(publish, index) in categories" :key="index">
            <router-link class="nav-link" :to="{name:'Categorys',params:{id:publish.id, name_cat: publish.name_ar}}">
               <i class="fas fa-fw fa-users"></i>&nbsp;
               <span>{{ publish.name_ar }}</span>
            </router-link>
        </li>
    </ul>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from 'vue';
export default {
  name: 'AppSidebar',
  setup() {
        // const router = useRouter();
        const categories = ref([]);

        try {
            HTTP.get(`GetAllCategories.php`).then((res) => {
                categories.value = res.data.Categories; 
            });
        } catch (err) {
            console.log(err);
        }
        
        return { categories };
    },
}
</script>

<style>

</style>
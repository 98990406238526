import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { VueCookieNext } from 'vue-cookie-next'
import VueLazyload from 'vue-lazyload'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const loadimage = require('./assets/Da7ktna_logo.png')
const errorimage = require('./assets/Da7ktna_logo.png')

createApp(App)
    .use(router)
    .use(VueCookieNext)
    .use(VueLazyload, {
        preLoad: 1.3,
        error: errorimage,
        loading: loadimage,
        attempt: 1
    })
    .use(Toast, {
        transition: "Vue-Toastification__fade",
        maxToasts: 30,
        newestOnTop: true,
        timeout: 4000,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: true,
        rtl: true
    })
    .mount('#app')

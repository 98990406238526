<template>
  <app-header />
  <div id="wrapper">
    <app-sidebar />
    <div id="content-wrapper">
      <div class="container-fluid pb-0">
        <Suspense>
          <template #default>
            <app-home />
          </template>
          <template #fallback>
            <loading />
          </template>
        </Suspense>
      </div>
      <app-footer />
    </div>
  </div>
  <a class="scroll-to-top rounded" href="#page-top">
      <i class="fas fa-angle-up"></i>
      </a>
      
      <div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
         <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
                  <button class="close" type="button" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                  </button>
               </div>
               <div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
               <div class="modal-footer">
                  <button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
                  <a class="btn btn-primary" href="login.html">Logout</a>
               </div>
            </div>
         </div>
      </div>
</template>

<script>
import AppFooter from "../components/Include/AppFooter.vue";
import AppHeader from "../components/Include/AppHeader.vue";
import AppSidebar from "../components/Include/AppSidebar.vue";
import { defineAsyncComponent, Suspense } from "vue";
import Loading from "../components/Include/Loading.vue";
const AppHome = defineAsyncComponent(() =>
  import("../components/Pages/AppHome.vue")
);
export default {
  name: "Home",
  components: {
    AppHeader,
    AppSidebar,
    AppFooter,
    AppHome,
    Loading,
    Suspense,
  },
};
</script>

import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/categorys/:id/:name_cat',
    name: 'Categorys',
    component: () => import(/* webpackChunkName: "categorys" */ '../views/Page/Categorys.vue'),
    props: true
  },
  {
    path: '/contents/:id',
    name: 'Contents',
    component: () => import(/* webpackChunkName: "contents" */ '../views/Page/Contents.vue'),
    props: true
  },
  {
    path: '/searchs/:search_id',
    name: 'Searchs',
    component: () => import(/* webpackChunkName: "searchs" */ '../views/Page/Searchs.vue'),
    props: true
  },
  {
    path: '/logins',
    name: 'Logins',
    component: () => import(/* webpackChunkName: "logins" */ '../views/User/Logins.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})
export default router
